import styled from "styled-components"
import { mq } from "styles"

export const RightRailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column: 2 / span 10;

  ${mq.maxWidth("md")} {
    margin-top: 24px;
  }

  ${mq.maxWidth("lg")} {
    margin-top: 40px;
  }

  ${mq.minWidth("md")} {
    grid-column: 3 / span 12;
  }

  ${mq.minWidth("lg")} {
    grid-column: 16 / span 8;
  }
`

export const RightRailTitleContainer = styled.div`
  ${mq.maxWidth("lg")} {
    display: none;
  }
`
