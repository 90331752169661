import styled from "styled-components"
import UndoArrowSvg from "assets/svg/arrow-undo.svg"
import RedoArrowSvg from "assets/svg/arrow-redo.svg"
import DownloadSvg from "assets/svg/download.svg"
import { mq } from "styles"
import { Button } from "components/UI/Button/Button"
import { $Color } from "typings/modules"

export const UndoArrowIcon = styled(UndoArrowSvg)<$Color>`
  color: ${({ $color }) => $color};
`
export const RedoArrowIcon = styled(RedoArrowSvg)<$Color>`
  color: ${({ $color }) => $color};
`
export const DownloadIcon = styled(DownloadSvg)<$Color>`
  color: ${({ $color }) => $color};
`

export const ActionButton = styled(Button)`
  height: 40px;

  ${mq.minWidth("md")} {
    height: 48px;
  }
`

export const StyledCanvas = styled.canvas`
  touch-action: none;
`

export const ActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
`
