import React from "react"
import { AccordionItem } from "components/AccordionItem/AccordionItem"
import { AccordionList } from "components/AccordionList/AccordionList"
import {
  ColorSelector,
  SelectColorOption,
} from "components/ColorOptions/ColorSelector"
import { ColorDescriptor } from "components/ColorOptions/ColorDescriptor"
import { Dropdown } from "components/UI/Dropdown/Dropdown"
import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import {
  changeDrawColor,
  changeTileMatSize,
  changeTileMatColor,
} from "redux/tileMat"
import { useTheme } from "styled-components"
import { usdFmt } from "utils/priceUtils"
import { AddTilesToCart } from "./AddTilesToCart"
import { tileMatSizes } from "./TileMat.constants"
import { TileVariantColor } from "./TileMat.types"
import {
  SubHeading,
  HexagonColorsContainer,
  TileCount,
  AddedTilesSummary,
  AddedTilesContainer,
  AddedTilesTitle,
  AddedTilesLineItem,
  AddedTilesLineItemTitle,
  TotalLine,
  AddedTilesTotal,
} from "./TileMatRightRail.styles"
import { TileMatTitleBlock, TileMatTitleBlockProps } from "./TileMatTitleBlock"
import { TileHexagon } from "./TileHexagon"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { SanityAccordionItem } from "typings/graphql"
import { DetailsAccordionProduct } from "components/Products/DetailsAccordionProduct"
import { ProductDetails } from "components/Products/ProductDetails"
import { Tooltip, tooltipCentered } from "components/UI/Tooltip/Tooltip"
import {
  RightRailTitleContainer,
  RightRailWrapper,
} from "components/DesignTools/RightRailStyles"

const isColorDark = (hexcolor: string) => {
  const r = parseInt(hexcolor.substring(1, 3), 16)
  const g = parseInt(hexcolor.substring(3, 5), 16)
  const b = parseInt(hexcolor.substring(5, 7), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq <= 128
}

type ColorWithBrightness = TileVariantColor & { isDark: boolean }

type Props = { accordionItems: SanityAccordionItem[] } & TileMatTitleBlockProps

export const TileMatRightRail: React.FC<Props> = ({
  title,
  subTitle,
  accordionItems,
  ...props
}) => {
  const dispatch = useDispatch()
  const {
    selectedColorSettings,
    tiles,
    tileMatDimensions,
    colors,
    drawColorHex,
    allTileMatColorSettings,
  } = useSelector((state: RootState) => state.tileMatTool)
  const theme = useTheme()

  const colorsWithBrightness: ColorWithBrightness[] = colors?.map(color => ({
    ...color,
    isDark: isColorDark(color.hexCode),
  }))

  const colorMap = colorsWithBrightness?.reduce((obj, color) => {
    obj[color.hexCode] = { ...color }
    return obj
  }, {} as Record<string, ColorWithBrightness>)

  const colorCounts = tiles
    .flat()
    .reduce((obj: Record<string, number>, tile) => {
      if (
        !!tile.hexCode &&
        tile.hexCode !== selectedColorSettings.tileColorHex
      ) {
        if (!!obj[tile.hexCode]) {
          obj[tile.hexCode]++
        } else {
          obj[tile.hexCode] = 1
        }
      }

      return obj
    }, {})

  const tileSets: { color: TileVariantColor; count: number }[] = Object.entries(
    colorCounts
  ).reduce((acc, [hexCode, count]) => {
    if (colorMap[hexCode]) {
      acc.push({ color: colorMap[hexCode], count: Math.ceil(count / 75) })
    }
    return acc
  }, [])

  const totalPrice = tileSets.reduce(
    (sum, { color, count }) =>
      sum + (color.availableForSale ? color.price * count : 0),
    0
  )

  const productVariantQuantities = tileSets
    .filter(({ color }) => color.availableForSale)
    .map(({ color, count }) => ({
      variantId: color.variant.shopifyVariant.variantId,
      quantity: count,
    }))

  const tileMatColors: SelectColorOption[] = Object.entries(
    allTileMatColorSettings
  )?.map(([name, val]) => ({
    name,
    swatchOrOpacityHex: [val.tileColorHex],
  }))

  const handleTileMatColorSelected = (name: string) => {
    dispatch(
      changeTileMatColor({
        tileMatColorSettings: allTileMatColorSettings[name],
      })
    )
  }

  return (
    <RightRailWrapper>
      <RightRailTitleContainer>
        <TileMatTitleBlock title={title} subTitle={subTitle} />
      </RightRailTitleContainer>
      <div>
        <SubHeading>Tiles</SubHeading>
        <HexagonColorsContainer>
          {colorsWithBrightness?.map(
            color =>
              !color.variant?.disabled && (
                <Tooltip
                  key={color.hexCode}
                  position={tooltipCentered}
                  label={color.name}
                  $mode={"dark"}
                >
                  <NoStyleButton
                    onClick={() =>
                      dispatch(changeDrawColor({ colorHex: color.hexCode }))
                    }
                    aria-label={`Change draw color to ${color.name}`}
                  >
                    <TileHexagon
                      $bgColor={color.hexCode}
                      selected={drawColorHex === color.hexCode}
                    >
                      <TileCount
                        $color={
                          theme?.[color.isDark ? "light" : "dark"]?.text.primary
                            .opacityHex
                        }
                      >
                        {(color.hexCode !==
                          selectedColorSettings.tileColorHex &&
                          colorCounts[color.hexCode]) ??
                          0}
                      </TileCount>
                    </TileHexagon>
                  </NoStyleButton>
                </Tooltip>
              )
          )}
        </HexagonColorsContainer>
      </div>
      <div>
        <Dropdown
          position="bottom left"
          label="Tile Mat"
          renderButton={
            <>
              {
                tileMatSizes.find(
                  ({ title }) => title === tileMatDimensions.title
                )?.title
              }
            </>
          }
          renderList={tileMatSizes
            .filter(({ title }) => title !== tileMatDimensions.title)
            .map(dimensions => (
              <DropdownItem
                aria-label={dimensions.title}
                key={dimensions.title}
                onClick={() =>
                  dispatch(
                    changeTileMatSize({
                      tileMatDimensions: dimensions,
                    })
                  )
                }
              >
                {dimensions.title}
              </DropdownItem>
            ))}
        />
      </div>
      {tileMatColors && selectedColorSettings && (
        <div>
          <ColorDescriptor label="Color" value={selectedColorSettings.name} />
          <ColorSelector
            mode="dark"
            colors={tileMatColors}
            selectedColorName={selectedColorSettings.name.toLowerCase()}
            onColorSelected={({ name }) => handleTileMatColorSelected(name)}
          />
        </div>
      )}
      {!!tileSets.length && (
        <AddedTilesSummary>
          <AddedTilesContainer>
            <AddedTilesTitle>Tile Sets Needed</AddedTilesTitle>
            {tileSets.map(({ color, count }) => (
              <AddedTilesLineItem key={color.name}>
                <AddedTilesLineItemTitle>
                  {count > 1 ? `${count}x ` : null}
                  {color.name} (75 pcs.)
                </AddedTilesLineItemTitle>
                <div>
                  {!color.availableForSale
                    ? "SOLD OUT"
                    : usdFmt(color.price * count)}
                </div>
              </AddedTilesLineItem>
            ))}
          </AddedTilesContainer>
          <TotalLine>
            <AddedTilesTotal>Total</AddedTilesTotal>
            <div>+{usdFmt(totalPrice)}</div>
          </TotalLine>
        </AddedTilesSummary>
      )}
      <AddTilesToCart
        disabled={!productVariantQuantities.length}
        variants={productVariantQuantities}
      />
      <AccordionList styleType="secondary">
        {accordionItems?.map(accordionItem => (
          <AccordionItem
            key={`tile-mat-accordion-item-${accordionItem.id}`}
            eventTrackingId={accordionItem.internalName}
            openByDefault={accordionItem.expanded}
            title={accordionItem.title}
            styleType="secondary"
          >
            {!!accordionItem.product ? (
              <DetailsAccordionProduct
                mode="dark"
                productItem={accordionItem.product}
              />
            ) : (
              <ProductDetails items={accordionItem._rawContent} />
            )}
          </AccordionItem>
        ))}
      </AccordionList>
    </RightRailWrapper>
  )
}
