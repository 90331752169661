import React from "react"
import styled, { css } from "styled-components"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

type Selected = { selected: boolean }

const filterDropShadow = css`
  filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.16))
    drop-shadow(0 5px 3px rgba(0, 0, 0, 0.23));
`

const Wrapper = styled.div<Selected>`
  display: inline-flex;

  justify-content: space-around;
  align-items: center;

  transition: filter 100ms ease-out;

  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24));

  &:hover {
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.12))
      drop-shadow(0 5px 5px rgba(0, 0, 0, 0.33));
  }

  ${({ selected }) => selected && filterDropShadow}
`

const Hexagon = styled.div<$BgColor>`
  width: 28.5px;
  height: 26px;

  ${mq.minWidth("sm")} {
    width: 46px;
    height: 40px;
  }

  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor}`};
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
`

type Props = Selected & $BgColor

export const TileHexagon: React.FC<Props> = ({
  $bgColor,
  selected,
  children,
}) => {
  return (
    <Wrapper selected={selected}>
      <Hexagon $bgColor={$bgColor} />
      {children}
    </Wrapper>
  )
}
