import {
  DesignToolCanvasWrapper,
  DesignToolTitleWrapper,
} from "components/DesignTools/DesignToolsStyles"
import { CircleButton } from "components/UI/Button/CircleButton"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useWindowSize } from "react-use"
import { RootState } from "redux/rootReducer"
import { drawFinished, initialize, redo, undo, reset } from "redux/tileMat"
import {
  HALF_MOON_CIRCLE_CENTER_HEIGHT,
  HALF_MOON_CIRCLE_STROKE_WIDTH,
} from "./TileMat.constants"
import {
  Tiles,
  TileDimensions,
  Tile,
  TileVariantColor,
  TileMatColorSettings,
  TileMatDimensions,
  TileMatShapeTypes,
} from "./TileMat.types"
import {
  ActionsContainer,
  UndoArrowIcon,
  RedoArrowIcon,
  StyledCanvas,
  ActionButton,
  DownloadIcon,
} from "./TileMatCanvas.styles"
import { TileMatTitleBlock, TileMatTitleBlockProps } from "./TileMatTitleBlock"

type BufferTile = { x: number; y: number; hexCode: string }

const drawHalfMoon = (
  context: CanvasRenderingContext2D,
  tileDimensions: TileDimensions
) => {
  const circleCenterX = tileDimensions.canvasWidth / 2
  const circleCenterY = tileDimensions.radius * HALF_MOON_CIRCLE_CENTER_HEIGHT
  const arcRadius = circleCenterX - tileDimensions.radius
  const strokeWidth = tileDimensions.diam * HALF_MOON_CIRCLE_STROKE_WIDTH
  // Draw outer Half Moon shape
  context.beginPath()
  context.moveTo(tileDimensions.radius, tileDimensions.radius)
  context.lineTo(
    tileDimensions.canvasWidth - tileDimensions.radius,
    tileDimensions.radius
  )
  context.arc(circleCenterX, circleCenterY, arcRadius, 0, Math.PI, false)
  context.closePath()
  context.stroke()
  context.fill()
  // Draw inner Half Moon shape
  context.beginPath()
  context.moveTo(
    tileDimensions.radius + strokeWidth,
    tileDimensions.radius + strokeWidth
  )
  context.lineTo(
    tileDimensions.canvasWidth - strokeWidth - tileDimensions.radius,
    tileDimensions.radius + strokeWidth
  )
  context.arc(
    circleCenterX,
    circleCenterY,
    arcRadius - strokeWidth,
    0,
    Math.PI,
    false
  )
  context.closePath()
  context.stroke()
  context.clip()
}

const drawTiles = (
  canvas: HTMLCanvasElement,
  newTiles: Tiles,
  context: CanvasRenderingContext2D,
  tileDimensions: TileDimensions,
  tileMatDimensions: TileMatDimensions,
  colorSettings: TileMatColorSettings
) => {
  context.fillStyle = colorSettings.backgroundColorHex

  if (tileMatDimensions.shape === TileMatShapeTypes.HalfMoon) {
    drawHalfMoon(context, tileDimensions)
  } else {
    context.fillRect(0, 0, canvas.width, canvas.height)
  }

  newTiles.forEach(row =>
    row.forEach(tile => drawTile(tile, context, tileDimensions, colorSettings))
  )
}

function pointToPointDistance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

function drawAtPosition(
  canvas: HTMLCanvasElement,
  clientX: number,
  clientY: number,
  tileDimensions: TileDimensions,
  tiles: Tiles,
  colorSettings: TileMatColorSettings,
  drawColorHex: string,
  drawnTiles: BufferTile[]
) {
  const { border, radius, diam } = tileDimensions
  const canvasContext = canvas.getContext("2d")
  const rect = canvas.getBoundingClientRect()
  const x = clientX - rect.left
  const y = clientY - rect.top

  // Find the two possible rows (y-axis)
  const y1 = Math.floor((y - border) / radius)
  const y2 = y1 + 1

  // x-axis - every second row is shifted right
  const x1 = Math.floor((x - border - (diam + radius) / 2) / (diam + radius))
  const x2 = x1 + 1

  for (const possible of [
    [x1, y1],
    [x1, y2],
    [x2, y1],
    [x2, y2],
  ]) {
    // checks if there is a displayed tile at the position
    if (!!tiles[possible[0]]?.[possible[1]]?.displayTile) {
      const distanceToTileCenter = pointToPointDistance(
        tiles[possible[0]][possible[1]].x,
        tiles[possible[0]][possible[1]].y,
        x,
        y
      )
      if (distanceToTileCenter < radius) {
        const tileColorHex =
          drawColorHex === colorSettings.tileColorHex ? undefined : drawColorHex

        const possibleTile = tiles[possible[0]][possible[1]]

        if (!possibleTile?.disableTile) {
          drawTile(
            { ...tiles[possible[0]][possible[1]], hexCode: tileColorHex },
            canvasContext,
            tileDimensions,
            colorSettings
          )

          drawnTiles.push({
            x: possible[0],
            y: possible[1],
            hexCode: tileColorHex,
          })
          break
        }
      }
    }
  }
}

function drawTile(
  tile: Tile,
  context: CanvasRenderingContext2D,
  tileDimensions: TileDimensions,
  colorSettings: TileMatColorSettings
) {
  if (!tile.displayTile) {
    return
  }

  const { radius, diam } = tileDimensions

  // draws a hexagon
  context.beginPath()
  context.moveTo(tile.x + diam * Math.cos(0), tile.y + diam * Math.sin(0))
  for (let side = 0; side < 7; side++) {
    context.lineTo(
      tile.x + radius * Math.cos((side * 2 * Math.PI) / 6),
      tile.y + radius * Math.sin((side * 2 * Math.PI) / 6)
    )
  }
  context.fillStyle = tile.hexCode ?? colorSettings.tileColorHex
  context.fill()
  context.strokeStyle = colorSettings.backgroundColorHex
  context.lineWidth = 0.75
  context.stroke()
}

type Props = {
  variantColors: TileVariantColor[]
  tileMats: Record<string, TileMatColorSettings>
} & TileMatTitleBlockProps

let drawnTiles: BufferTile[] = []
let isDrawing = false

export const TileMatCanvas: React.FC<Props> = ({
  title,
  subTitle,
  variantColors,
  tileMats,
  ...props
}) => {
  const dispatch = useDispatch()
  const {
    selectedColorSettings,
    tiles,
    tileDimensions,
    tileMatDimensions,
    drawColorHex,
    currentActionIndex,
    userActions,
  } = useSelector((state: RootState) => state.tileMatTool)

  const canvasContainerRef = useRef<HTMLDivElement>()
  const canvasRef = useRef<HTMLCanvasElement>()
  const windowSize = useWindowSize()

  useEffect(() => {
    if (canvasContainerRef?.current && variantColors) {
      dispatch(
        initialize({
          containerWidth: canvasContainerRef?.current.offsetWidth,
          colors: variantColors,
          allTileMatColorSettings: tileMats,
        })
      )
    }
  }, [canvasContainerRef, variantColors])

  useEffect(() => {
    if (
      !!tiles &&
      !!canvasRef?.current &&
      !!tileDimensions &&
      !!tileMatDimensions
    ) {
      const canvasElement = canvasRef.current

      canvasElement.width = tileDimensions.canvasWidth
      canvasElement.height = tileDimensions.canvasHeight

      drawTiles(
        canvasElement,
        tiles,
        canvasElement.getContext("2d"),
        tileDimensions,
        tileMatDimensions,
        selectedColorSettings
      )
    }
  }, [tiles, canvasRef?.current, tileDimensions, tileMatDimensions])

  useEffect(() => {
    dispatch(
      initialize({
        containerWidth: canvasContainerRef?.current.offsetWidth,
      })
    )
  }, [windowSize.width])

  const onDrawStarted = (e: any) => {
    isDrawing = true
    drawnTiles = []
    drawAtPosition(
      canvasRef.current,
      e.clientX,
      e.clientY,
      tileDimensions,
      tiles,
      selectedColorSettings,
      drawColorHex,
      drawnTiles
    )
  }

  const onDrawEnded = () => {
    isDrawing = false
    dispatch(drawFinished(drawnTiles))
  }

  const onDrawCancel = () => {
    if (isDrawing) {
      dispatch(drawFinished(drawnTiles))
    }
    isDrawing = false
  }

  const onDrawMove = (e: any) => {
    if (isDrawing) {
      let x = e.clientX
      let y = e.clientY

      if (e.touches?.length) {
        const touch = e.touches[0]

        x = touch.clientX
        y = touch.clientY
      }

      drawAtPosition(
        canvasRef.current,
        x,
        y,
        tileDimensions,
        tiles,
        selectedColorSettings,
        drawColorHex,
        drawnTiles
      )
    }
  }

  const undoClicked = () => {
    dispatch(undo())
  }

  const redoClicked = () => {
    dispatch(redo())
  }

  const resetClicked = () => {
    dispatch(reset())
  }

  const downloadImage = () => {
    const link = document.createElement("a")
    link.download = "custom-tile-mat.png"
    link.href = canvasRef.current.toDataURL()
    link.click()
  }

  return (
    <DesignToolCanvasWrapper>
      <DesignToolTitleWrapper>
        <TileMatTitleBlock title={title} subTitle={subTitle} />
      </DesignToolTitleWrapper>
      <div ref={canvasContainerRef}>
        <StyledCanvas
          aria-label="Tile Mat Design Tool canvas"
          ref={canvasRef}
          onMouseDown={onDrawStarted}
          onMouseUp={onDrawEnded}
          onMouseOut={onDrawCancel}
          onBlur={onDrawCancel}
          onMouseMove={onDrawMove}
          onTouchStart={onDrawStarted}
          onTouchEnd={onDrawEnded}
          onTouchCancel={onDrawCancel}
          onTouchMove={onDrawMove}
        />
      </div>
      <ActionsContainer>
        <CircleButton
          variant="primary-dark"
          onClick={undoClicked}
          disabled={currentActionIndex <= 0}
          aria-label="Undo"
        >
          <UndoArrowIcon $color="white" />
        </CircleButton>
        <CircleButton
          variant="primary-dark"
          onClick={redoClicked}
          disabled={currentActionIndex >= userActions.length - 1}
          aria-label="Redo"
        >
          <RedoArrowIcon $color="white" />
        </CircleButton>
        <CircleButton
          variant="primary-dark"
          onClick={downloadImage}
          aria-label="Save Image"
        >
          <DownloadIcon $color="white" />
        </CircleButton>
        <ActionButton onClick={resetClicked} variant={"secondary-dark"}>
          Reset
        </ActionButton>
      </ActionsContainer>
    </DesignToolCanvasWrapper>
  )
}
