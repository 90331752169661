import styled from "styled-components"
import { mq } from "styles/mq"

export const DesignToolGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 80px 0;
  background-color: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};

  ${mq.minWidth("md")} {
    grid-template-columns: repeat(16, 1fr);
  }

  ${mq.minWidth("lg")} {
    grid-template-columns: repeat(24, 1fr);
  }
`

export const DesignToolCanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  grid-column: 2 / span 10;

  ${mq.minWidth("md")} {
    gap: 40px;
    grid-column: 3 / span 12;
  }

  ${mq.minWidth("lg")} {
    grid-column: 3 / span 11;
    gap: 48px;
  }
`

export const DesignToolTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 24px;

  ${mq.minWidth("md")} {
    margin-bottom: 32px;
  }

  ${mq.minWidth("lg")} {
    display: none;
  }
`
