import React from "react"
import { SanityTileMatDesignTool } from "typings/graphql"
import { TileMatDesignTool } from "./TileMatDesignTool"

export const SanityTileMatDesignToolBlock = ({
  fields,
}: {
  fields: SanityTileMatDesignTool
}) => (
  <TileMatDesignTool
    tileSanityProduct={fields.tileSetProduct}
    title={fields.title}
    subTitle={fields.subTitle}
    accordionItems={fields.accordionItems}
    blackMatDrawColor={fields.blackMatDrawColor}
    whiteMatDrawColor={fields.whiteMatDrawColor}
    {...fields}
  />
)

export default SanityTileMatDesignToolBlock
