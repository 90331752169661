import React, { useEffect, useState } from "react"
import {
  SanityAccordionItem,
  SanityDefinedColor,
  SanityProduct,
} from "typings/graphql"
import {
  TileMatColorSettings,
  TileVariantColor,
} from "components/TileMat/TileMat.types"
import { TileMatTitleBlockProps } from "./TileMatTitleBlock"
import { useQuery } from "@apollo/client"
import { shopifyApolloClient } from "config/apollo-clients/shopify"
import { getProductById } from "graphql/queries/product"
import { ProductMetaVariantConn } from "typings/modules"
import { TileMatCanvas } from "./TileMatCanvas"
import { TileMatRightRail } from "./TileMatRightRail"
import { DesignToolGrid } from "components/DesignTools/DesignToolsStyles"

type Props = {
  tileSanityProduct: SanityProduct
  accordionItems: SanityAccordionItem[]
  blackMatDrawColor: SanityDefinedColor
  whiteMatDrawColor: SanityDefinedColor
} & TileMatTitleBlockProps

export const TileMatDesignTool: React.FC<Props> = ({
  title,
  subTitle,
  tileSanityProduct,
  accordionItems,
  blackMatDrawColor,
  whiteMatDrawColor,
}) => {
  const [variantColors, setVariantColors] = useState<TileVariantColor[]>([])

  const {
    data: shopifyData,
    loading: shopifyLoading,
  } = useQuery<ProductMetaVariantConn>(getProductById, {
    variables: {
      ids: [tileSanityProduct?.shopifyProduct.shopifyId],
    },
    client: shopifyApolloClient,
  })

  useEffect(() => {
    if (shopifyLoading || !shopifyData?.nodes.length) {
      return
    }

    const shopifyVariants = shopifyData.nodes[0].variants?.edges.map(
      e => e.node
    )

    const colors =
      tileSanityProduct.variants
        ?.map(variant => {
          const shopifyVariant = shopifyVariants.find(
            shopifyVariant =>
              shopifyVariant.id === variant.shopifyVariant.variantId
          )

          if (!shopifyVariant) return null

          return {
            name: variant.shopifyVariant.title,
            hexCode: (variant.swatch[0] as SanityDefinedColor)?.opacityHex,
            variant,
            availableForSale: shopifyVariant.availableForSale,
            price: Number(shopifyVariant.price),
          }
        }) // filter out any swatch that doesn't have a hexCode (uses an image instead)
        .filter(color => !!color?.hexCode) || []

    setVariantColors(colors)
  }, [shopifyData?.nodes, shopifyLoading])

  const presetTileMatColorSettings: Record<string, TileMatColorSettings> = {
    black: {
      name: "Black",
      backgroundColorHex: "#2d2a25",
      defaultDrawColorHex: blackMatDrawColor?.opacityHex,
      tileColorHex: "#202020",
    },
    white: {
      name: "White",
      backgroundColorHex: "#2d2a25",
      defaultDrawColorHex: whiteMatDrawColor?.opacityHex,
      tileColorHex: "#fafafa",
    },
  }

  return (
    <DesignToolGrid>
      <TileMatCanvas
        title={title}
        subTitle={subTitle}
        variantColors={variantColors}
        tileMats={presetTileMatColorSettings}
      />
      <TileMatRightRail
        title={title}
        subTitle={subTitle}
        accordionItems={accordionItems}
      />
    </DesignToolGrid>
  )
}
