import styled from "styled-components"
import { typography } from "styles"

export const SubHeading = styled.p`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
  margin-bottom: 8px;
`
export const HexagonColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

export const TileCount = styled.div<{ $color: string }>`
  ${typography.bodySmall};
  line-height: inherit;
  color: ${({ $color }) => $color};
  position: absolute;
`

export const AddedTilesSummary = styled.div`
  ${typography.bodySmall};
  color: ${({ theme }) => theme?.dark?.text?.primary?.opacityHex};
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
  border-bottom: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
`
export const AddedTilesTitle = styled.div`
  color: ${({ theme }) => theme?.dark?.text?.secondary?.opacityHex};
`
export const AddedTilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const AddedTilesLineItem = styled.div`
  display: flex;
`
export const AddedTilesLineItemTitle = styled.div`
  flex-grow: 1;
`
export const TotalLine = styled(AddedTilesLineItem)`
  margin-top: 20px;
`
export const AddedTilesTotal = styled(AddedTilesLineItemTitle)`
  font-weight: bold;
`
