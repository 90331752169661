import { Button } from "components/UI/Button/Button"
import { StoreContext } from "contexts/StoreContext"
import React, { useCallback, useContext } from "react"

type Props = {
  variants: { variantId: string; quantity: number }[]
} & React.ComponentProps<typeof Button>

export const AddTilesToCart: React.FC<Props> = ({
  variants,
  disabled,
  ...props
}) => {
  const { addLineItems, setCartOpen } = useContext(StoreContext)

  const addToCartClicked = useCallback(() => {
    addLineItems(variants)
    setCartOpen(true)
  }, [variants, addLineItems, setCartOpen])

  return (
    <Button
      onClick={addToCartClicked}
      fullWidth={true}
      variant={disabled ? "disabled" : "primary-dark"}
      loading={false}
      disabled={disabled}
      {...props}
    >
      Add All to Cart
    </Button>
  )
}
